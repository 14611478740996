@import "landingFiles/fonts/fonts.css";

@import 'landingFiles/variables';

@import 'swiper/swiper-bundle.css';
@import 'landingFiles/landing';
@import 'src/assets/styles/common';

body {
  font-family: var(--bs-font-sans-serif), sans-serif;
  background: $bodyBg;
}
