// Custom Variables
$customDanger: #ED2E7E;
$blue: #EF3124;
$primary: #EF3124;
$btn-primary-color: #fff;
$asphalt: #1D2939;
$gray1: #F2F4F7;
$gray2: #E4E7EC;
$gray3: #98A2B3;
$systemNotification: #EFF8FF;
$alertWarning: #FFFAEB;
$customBorderRadius: 16px;
$bodyBg: #F2F4F7;
$pageTitleColor: #626F80;
$customHoverBorder: #626F80;

// Переопределяем размеры контейнера
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
);

:root {
  --bs-font-sans-serif: 'SF Pro Display';
  --title-font: 'Styrene B UI Web';
  --icon-fill-color: #EF3124;
  --bs-card-spacer-x: 1.5rem;
  --bs-card-cap-padding-x: 1.5rem;
  --bs-breadcrumb-divider: '|';
  --bs-secondary-color: $gray3;
  --bs-breadcrumb-divider-color: $gray3;
  --bs-danger: $customDanger;
}
