@import 'variables';
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

header {
  h1 {
    font-size: 100px;
    font-weight: 800;
    line-height: 80px;
    letter-spacing: -1px;
    text-align: left;
    color: $asphalt;

    max-width: 410px;

    @include media-breakpoint-down(sm) {
      font-size: 72px;
      font-weight: 800;
      line-height: 60px;
      text-align: left;
    }
  }

  p {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    color: $asphalt;

    max-width: 340px;

    @include media-breakpoint-down(sm) {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  .separator {
    width: 1px;
    height: 30px;
    background-color: $gray3;
    opacity: 0.3;
  }
}

section {
  .title {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;

    @include media-breakpoint-down(sm) {
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
      text-align: left;
    }
  }
}

.alfaIdBtn {
  height: 48px!important;
  img {
    width: 24px;
    height: 24px;
  }
}
.nameFromAlfaId {
  font-size: 18px;
  font-weight: 500;
}

// FORM
.page-title {
  display: none;
}
// end FORM

// CREATE POLICY
.payment-card-icon {
  margin-right: 10px;
}
// end CREATE POLICY
